import React from "react";
require("@/styles/nicePage.less");

/**
 * Simple page styling, for basic pages (e.g. login etc.)
 */
const NicePage: React.FC<Record<string, unknown>> = ({
  children,
  ...otherProps
}) => {
  return (
    <div className={"nice-page"} {...otherProps}>
      {children}
    </div>
  );
};

export default NicePage;
