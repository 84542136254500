import React, { useCallback, useEffect, useState } from "react";
import Layout, { LAYOUT_QUERY } from "@/components/Layout";

import { Form, Input, Button, Checkbox, Typography } from "antd";

import { useRouter } from "next/router";

const { Title } = Typography;

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { RADIO_STATE } from "@/store/liveRadio/action";
import { connect } from "react-redux";
import LoginUtils from "@/utils/auth/LoginUtils";
import { compose } from "redux";
import { fetchAPI } from "@/utils/api/api";
import NicePage from "@/components/NicePage";

require("@/styles/login.less");
import { signinOnLoad } from "@/store/auth/action";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const LOGIN_PAGE_QUERY = `
query GetLoginPage {
	${LAYOUT_QUERY}
}
`;

const LoginPage = ({ graphQLData }) => {
  const router = useRouter();

  const [errorMessage, setErrorMessage] = useState(null);

  // get user role from redux state
  const role = useSelector<RootState>(({ auth }) => auth.role);

  const dispatch = useDispatch();

  useEffect(() => {
    // a redux action that sends a one-time request to "/user/signed" on initial page load
    // which sets a role as "guest" or "member" when resolved
    // since this is a client-side request, the cookie logic isn't needed
    if (role !== "guest" && role != null) router.push("/");
  }, [role, router]);

  const onFinish = useCallback(
    async ({ identifier, password }) => {
      if (typeof window === "undefined") {
        return;
      }

      let loginResp = false;
      try {
        loginResp = await LoginUtils.login({ identifier, password });
      } catch (e) {
        setErrorMessage("Error logging in, incorrect details!");
        console.error("Error logging in", e);
        return;
      }
      if (!loginResp) {
        setErrorMessage("Error logging in, incorrect details!");
        return;
      }

      // iff succesful
      if (router != null) {
        dispatch(signinOnLoad());
        router.push("/");
      }
    },
    [router, dispatch]
  );

  return (
    <Layout title="Login" data={graphQLData}>
      <NicePage>
        <Form
          {...layout}
          className={"login-form"}
          onFinish={onFinish}
          layout={"horizontal"}
        >
          <Title>Login</Title>
          <Form.Item
            label="Username"
            name="identifier"
            validateStatus={errorMessage && "error"}
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            validateStatus={errorMessage && "error"}
            help={errorMessage || ""}
          >
            <Input.Password />
          </Form.Item>
          {/*
          <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </NicePage>
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  playing: state.liveRadio.state === RADIO_STATE.PLAYING,
});

export async function getStaticProps() {
  const data = await fetchAPI(LOGIN_PAGE_QUERY);
  return {
    props: {
      graphQLData: data,
    },
    revalidate: 1,
  };
}

const connector = connect(mapState);

export default compose(connector)(LoginPage);
